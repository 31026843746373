import { render, staticRenderFns } from "./coursesIntro.vue?vue&type=template&id=52a925a4&scoped=true&"
import script from "./coursesIntro.vue?vue&type=script&lang=js&"
export * from "./coursesIntro.vue?vue&type=script&lang=js&"
import style0 from "./coursesIntro.vue?vue&type=style&index=0&id=52a925a4&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52a925a4",
  null
  
)

export default component.exports