//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Ticker from '@/components/courses/black-friday/ticker.vue';

export default {
  name: 'CourseCard',
  components: { Ticker },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    // cardType: 'default' или 'sale'
    cardType: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      hovered: false,
    };
  },
  computed: {
    isMx() {
      return this.$nuxt.$config.COUNTRY === 'mx';
    },
    isBr() {
      return this.$nuxt.$config.COUNTRY === 'br';
    },
    url() {
      return this.data.pageUrl;
    },
    label() {
      if (this.data.topSale) return 'topSale';
      if (this.data.soldOut) return 'soldOut';
      if (this.$app.config.current.jobGuarantee && this.data.jobGuarantee) {
        return 'jobGuarantee';
      }
      if (this.data.free) return 'free';
      return null;
    },
    backgroundColor() {
      return this.data?.backgroundColor;
    },
    backgroundImage() {
      return this.data?.images?.showcaseImage?.url;
    },
    badges() {
      const list = [];

      const type = this.data.type;
      list.push({
        text: this.$t(`courses.cards.badges.type.${type}`),
      });

      const duration = this.data.duration;
      list.push({
        text: this.$t(`courses.cards.duration`, { duration }),
      });

      return list;
    },
  },
};
