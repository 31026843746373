//
//
//
//
//
//
//

import LoaderBlack from '@/assets/svg/ic-loader-black.svg?inline';
import LoaderWhite from '@/assets/svg/ic-loader.svg?inline';

export default {
  name: 'PageLoader',
  components: {
    LoaderBlack,
    LoaderWhite,
  },
  props: {
    black: {
      type: Boolean,
      default: true,
    },
    fullsize: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
  },
};
