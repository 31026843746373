//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    isBr() {
      return this.$nuxt.$config.COUNTRY === 'br';
    },
    isMx() {
      return this.$nuxt.$config.COUNTRY === 'mx';
    },
  },
};
